import { Avatar, Badge, Tag, Tooltip } from 'antd'
import React from 'react'
import BELL_ICON from '../../assets/icons/noti/bell.svg'
import { Link } from 'react-router-dom'
import useNotificationBell from './useNotificationBell'
import { NotificationBell } from '../../models/Notification'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'

export default function NotiBell() {
  const { count, currentCount, notificationBells } = useNotificationBell()
  const {choosingClient, choosingCompany, choosingWarehouse} = useSelector((state: RootState) => state.account);

  function genToolTip() {
    const tooltips: NotificationBell[] = [];
    notificationBells.forEach(value => {
      if (value.count === 0) return;
      tooltips.push(value);
    })

    return tooltips.map(tooltip => {
      const {
        client_code_nom = '',
        company_code_nom = '',
        warehouse_code_nom = '',
        count = 0
      } = tooltip;
      const content = `${client_code_nom}-${warehouse_code_nom}-${company_code_nom}: ${count}`
      return <div>{content}</div>
    })
  }
  function getCountDisplay(){
    if(!choosingClient || !choosingCompany || !choosingWarehouse) return count;
    if(count === 0) return '';
    return `${currentCount}/${count}`
  }
  return (
    <Tooltip placement='top' title={genToolTip()} >
      <Link to={'notifications'}>
        <Badge count={getCountDisplay()} size={"small"}>
          <img src={BELL_ICON} className="cursor-pointer" alt='noti' />
        </Badge>
      </Link>

    </Tooltip>
  )
}
